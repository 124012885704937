import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import withSize from "react-sizeme"
import StackGrid from "react-stack-grid"
import PostPreview from "./postPreview/postPreview"

const PostFeed = ({ size }) => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query MyQuery {
      allMarkdownRemark(
        limit: 20
        sort: { fields: frontmatter___date, order: DESC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              coverImage {
                childImageSharp {
                  fluid(maxWidth: 980) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              date
            }
            excerpt(format: HTML)
          }
        }
      }
    }
  `)
  return (
    <StackGrid
      columnWidth={size.width <= 700 ? "100%" : "50%"}
      monitorImagesLoaded={true}
      gutterWidth={80}
      gutterHeight={40}
    >
      {allMarkdownRemark.edges.map(({ node }) => {
        return (
          <PostPreview
            key={node.id}
            title={node.frontmatter.title}
            date={node.frontmatter.date}
            slug={node.fields.slug}
            excerpt={node.excerpt}
            image={node.frontmatter.coverImage}
          />
        )
      })}
    </StackGrid>
  )
}

export default withSize()(PostFeed)
