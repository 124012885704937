import React from "react"
import { Link } from "gatsby"
import { format } from "date-fns"
import Image from "gatsby-dynamic-image"

import HighlightText from "../highlightText"
import styles from "./postPreview.module.css"

const PostPreview = ({ title, excerpt, date, slug, image }) => {
  return (
    <Link className={styles.link} to={`/${slug}`}>
      {image && <Image className={styles.img} node={image} alt="featured" />}
      <div className={styles.content}>
        <h2>
          <HighlightText>{title}</HighlightText>
        </h2>
        <h3>{format(new Date(date), "PP")}</h3>
        <div dangerouslySetInnerHTML={{ __html: excerpt }} />
      </div>
    </Link>
  )
}

export default PostPreview
